import React from "react"

import "../../styles/index.scss"
import "../../styles/secondary_pages.scss"

import Layout from "../../components/layout"
import SEO from "../../components/seo"


import { Link, graphql } from "gatsby"


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'


import BannerMobile from "../../images/editorial/health-system-banner-mobile.jpg"
import Banner from "../../images/editorial/health-system-banner.jpg"



import IconHealthSystem from "../../images/svg/health-system.svg"
import IconDrSuitcase from "../../images/svg/doctor-suitcase.svg"
import IconEYK from "../../images/svg/everything-you-know.svg"
import IconOutstandingExperience from "../../images/svg/outstanding-experience.svg"
import IconProfits from "../../images/svg/profits.svg"
import IconSmartStrategy from "../../images/svg/smart-strategy.svg"
import IconStayAhead from "../../images/svg/stay-ahead.svg"
import IconTechnology from "../../images/svg/technology.svg"



const RevCycle = () => (

  <React.Fragment>
    <SEO title="Health System" description="Invigorate your bottom line. For more than 35 years, MSM has been partnering with practices to maximize profits and drive down costs." />

    <Layout>

      <section id="sct-uvp" className="container ctr-headline secondary-headline">
        <div className="frame-image">
          <article>

            <h2>Think beyond the
            <br />
              <span>hospital. </span>
            </h2>
            <p>Partner with MSM to launch a patient-centric ambulatory care strategy for your health system. </p>
            <button className="btn-primary">
              <a href="#learnmore"> Learn More</a>
            </button>
          </article>

          <picture>
            <source srcSet={BannerMobile} media="(max-width:600px)" />
            <source srcSet={Banner} />
            <img src={Banner} alt="" className="lazyload" />
          </picture>


        </div>
      </section>

      <section className="container ctr-potential ctr-newframework trmnt-special" id="sct-where-they-are" style={{ backgroundColor: "#389ebf" }}>
        <div className="animated ">
          <h2>Meet patients where they are</h2>
          <p>As a health system leader, you want to deliver great care where your patients want it—whether that’s at an urgent care, imaging center, or with their primary care physician. But expanding beyond the traditional hospital model to an ambulatory care approach involves multiple moving parts. We’re here to provide you with the expert guidance you need to make the right investments now—so you’re well-positioned for a thriving ambulatory care business later. </p>
        </div>


        <img src={IconHealthSystem} />

      </section>
      <a name="learnmore" />
      <section className="container ctrinfoGraphicContainer" >
        <h3>How MSM helps health systems expand into ambulatory care </h3>
        <div className="content">
          <article className="ctrInfoGraphic">
            <div className="article-content">
              <h4>
                Map out a smart strategy
      </h4>
              <p>From identifying acquisition and partnership opportunities to aligning around compensation models and clinical network best practices, we develop a detailed, comprehensive, and research-backed road map that sets you up for success.</p>
            </div>
            <div className="svg">
              <img src={IconSmartStrategy} alt="" />
            </div>
          </article>

          <article className="ctrInfoGraphic">
            <div className="article-content">
              <h4>
                Ensure an outstanding experience</h4>
              <p>It’s all about the patient experience—and our systems empower you to deliver amazing care consistently, and at scale. No offshore billing. No outsourced support. And all the choice, transparency, and flexibility patients have come to expect from a modern healthcare provider. </p>
            </div>
            <div className="svg">
              <img src={IconOutstandingExperience} alt="" />
            </div>

          </article>
          <article className="ctrInfoGraphic">
            <div className="article-content">
              <h4>Get the technology right</h4>
              <p>Our technology unifies EHRs from across your ambulatory network into a single interoperable system. Aggregated data gives you financial and operational visibility. All the while, your providers continue using their platforms of choice: uninterrupted. </p>
            </div>
            <div className="svg ">
              <img src={IconTechnology} alt="" />
            </div>

          </article>
          <article className="ctrInfoGraphic">
            <div className="article-content">
              <h4>Everything you need to start — and succeed</h4>
              <p>Don’t waste time and resources on trial and error. We have extensive experience running well-managed, cost-effective ambulatory practices. Let us handle HR, finance, technology, and clinical operations from day one — while you focus on championing your vision to the world. </p>
            </div>
            <div className="svg ">

              <img src={IconEYK} alt="" />
            </div>
          </article>
          <article className="ctrInfoGraphic">
            <div className="article-content">
              <h4>Maximize profitability across your network.</h4>
              <p>We have a track record of maximizing profitability in the ambulatory care space. Through smart revenue cycle management and right-sizing budgets, we do the heavy lifting to ensure every practice across your ambulatory network is optimized for financial success. </p>
            </div>
            <div className="svg svg-vert">
              <img src={IconProfits} alt="" />
            </div>
          </article>
        </div>

      </section>

      <section className="container list-container" style={{ backgroundColor: "#f6f7fa" }}>
        <h3>We have experience with all things ambulatory:</h3>

        <ul className="ctr-list-component">
          <li>
            <FontAwesomeIcon icon={faCaretRight} />Business Development</li>
          <li>
            <FontAwesomeIcon icon={faCaretRight} />Claims Adjudication</li>
          <li>
            <FontAwesomeIcon icon={faCaretRight} />Data Analytics</li>
          <li>
            <FontAwesomeIcon icon={faCaretRight} />EHR Deployment and Interoperability</li>
          <li>
            <FontAwesomeIcon icon={faCaretRight} />Financial Services</li>
          <li>
            <FontAwesomeIcon icon={faCaretRight} />Human Resources</li>
          <li>
            <FontAwesomeIcon icon={faCaretRight} />MACRA / MIPS and other P4P Programs</li>
          <li>
            <FontAwesomeIcon icon={faCaretRight} />Payor Contracting and Credentialing</li>
          <li>
            <FontAwesomeIcon icon={faCaretRight} />Physician Compensation Plans and Strategies</li>
          <li>
            <FontAwesomeIcon icon={faCaretRight} />Physician Enterprise Strategy</li>
          <li>
            <FontAwesomeIcon icon={faCaretRight} />Practice Startup / Acquisition</li>
          <li>
            <FontAwesomeIcon icon={faCaretRight} />Revenue Cycle Management</li>
          <li>
            <FontAwesomeIcon icon={faCaretRight} />Service Line Strategy</li>
          <li>
            <FontAwesomeIcon icon={faCaretRight} />Technology Services</li>
        </ul>

        <div className="svg">
          <img src={IconDrSuitcase} alt="" />
        </div>
      </section>

      <section className="container ctr-cta" style={{ backgroundColor: "#16cac3", color: "#fff" }}>
        <h3>Let’s think beyond the hospital together.</h3>
        <p> Get in touch to set a winning ambulatory care strategy in motion today.</p>
        <button className="btn-primary btn-inverse" style={{ backgroundColor: "#fff", color: "#16cac3" }}>
          <Link to="/contact">I'm in</Link>
        </button>
      </section>

    </Layout>




  </React.Fragment >

)

export default RevCycle;